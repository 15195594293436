body {
  background: #141b2d;
  font-family: "Montserrat", sans-serif;
}

*,
*:hover {
  transition-duration: 200ms;
}

.wrapper {
  height: calc(100vh - 200px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}

.box {
  padding: 20px;
  width: 50%;
  background: #1f2940;
  border-radius: 8px;
  height: calc(100% - 40px);
  overflow: auto;
}

.title {
  font-size: 32px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  margin-bottom: 20px;
}

.admins .item {
  padding: 16px;
  background: #2b3855;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admins .item:hover {
  cursor: pointer;
  background: #364668;
}

.admins .item .name {
  font-weight: 700;
}

.admins .item .label {
  color: #fff;
  padding: 5px 8px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 2em; /* Total width including `border-width` of scrollbar thumb */
  height: 0;
}
::-webkit-scrollbar-thumb {
  height: 1em;
  border: 0.75em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.owner {
  box-shadow: 0px 0px 3px rgba(255, 97, 97, 0.2);
  border: 1px solid rgba(255, 97, 97, 0.05);
  background: rgba(255, 97, 97, 0.3) !important;
}

.servermanager {
  box-shadow: 0px 0px 3px rgba(255, 242, 97, 0.2);
  border: 1px solid rgba(255, 242, 97, 0.05);
  background: rgba(255, 242, 97, 0.3) !important;
}

.developer {
  box-shadow: 0px 0px 3px rgba(50, 232, 252, 0.2);
  border: 1px solid rgba(50, 232, 252, 0.05);
  background: rgba(50, 232, 252, 0.3) !important;
}

.communityanager {
  box-shadow: 0px 0px 3px rgba(181, 97, 255, 0.2);
  border: 1px solid rgba(181, 97, 255, 0.05);
  background: rgba(181, 97, 255, 0.3) !important;
}

.factionmanager {
  box-shadow: 0px 0px 3px rgba(181, 97, 255, 0.2);
  border: 1px solid rgba(181, 97, 255, 0.05);
  background: rgba(181, 97, 255, 0.3) !important;
}

.eventmanager {
  box-shadow: 0px 0px 3px rgba(204, 146, 255, 0.2);
  border: 1px solid rgba(204, 146, 255, 0.05);
  background: rgba(204, 146, 255, 0.3) !important;
}

.admincontroller {
  box-shadow: 0px 0px 3px rgba(245, 173, 39, 0.2);
  border: 1px solid rgba(245, 173, 39, 0.05);
  background: rgba(245, 173, 39, 0.3) !important;
}

.headadmin {
  box-shadow: 0px 0px 3px rgba(50, 145, 252, 0.2);
  border: 1px solid rgba(50, 145, 252, 0.05);
  background: rgba(50, 145, 252, 0.3) !important;
}

.admin5,
.admin4,
.admin3,
.admin2,
.admin1 {
  box-shadow: 0px 0px 3px rgba(50, 232, 252, 0.2);
  border: 1px solid rgba(50, 232, 252, 0.05);
  background: rgba(50, 232, 252, 0.3) !important;
}

.moderator {
  box-shadow: 0px 0px 3px rgba(50, 245, 47, 0.2);
  border: 1px solid rgba(50, 245, 47, 0.05);
  background: rgba(50, 245, 47, 0.3) !important;
}

.dialog {
  padding: 20px;
  background: #1f2940;
  border-radius: 16px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.025);
}

.MuiPaper-root {
  background: transparent !important;
  box-shadow: none !important;
}

.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 8px 12px;
  background: #2b3855;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #fff;
}

.data .option {
  font-size: 14px;
  font-weight: 600;
}

.data .label {
  color: #fff;
  padding: 5px 8px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
  background: #696ffb;
}

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.MuiTextField-root {
  background: #2b3855;
  color: #fff;
}
.MuiFormLabel-root,
.MuiButtonBase-root,
.MuiPickersCalendarHeader-labelContainer,
.MuiTypography-caption,
.MuiPickersYear-root,
.MuiInputBase-input {
  color: #fff !important;
}

.MuiDateCalendar-root {
  background: #3e4e71;
}

.MuiPickersDay-root {
  color: #fff !important;
}
.Mui-selected {
  background: #696ffb !important;
}
